<template>
  <v-list
      class="mx-auto"
      max-width="800"
      color="transparent"
  >
    <draggable
        v-model="units"
        v-bind="dragOptions"
        @start="drag=true"
        @end="drag=false"
    >
      <v-list-item
          v-for="unit in units"
          :key="unit.id"
          two-line
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ unit.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ unit.varsity.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action style="min-width: 250px">
          {{ unit.form_fill_up_start | date }} - {{ unit.form_fill_up_end | date }}
        </v-list-item-action>
      </v-list-item>
    </draggable>
  </v-list>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import moment from "moment";

export default {
  name: "ManageFormFillUp",
  components: {
    draggable,
  },
  data() {
    return {
      units: [],
      drag: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  created() {
    this.initialize()
  },
  watch: {
    units: {
      handler(newVal, oldVal) {
        if (oldVal) {
          this.sortUnits()
        }
      },
      deep: true
    }
  },
  methods: {
    initialize() {
      const url = 'admin/units?filter=admin&sortBy=form_fill_up_start'
      axios.get(url).then((response) => {
        this.units = response.data
      })
    },
    sortUnits() {
      const url = 'admin/units'
      const data = {
        units: this.units,
        type: 'sort'
      }
      axios.post(url, data).then(() => {
      })
    }
  },
  filters: {
    date(value) {
      return moment(value).format('D MMM, Y')
    }
  }
}
</script>

<style scoped>

</style>